import './scss/main.scss';
import {lory} from 'lory.js';
import kladr from 'jquery.kladr';
import Inputmask from "inputmask";
import validation from 'jquery-validation';
import fit_modal from 'fit_modal';
// import FontFaceObserver from 'fontfaceobserver';

document.addEventListener('DOMContentLoaded', () => {
    const configSlider = {
        infinite: 1,
        enableMouseEvents: true,
        classNameFrame: 'block__slider__frame',
        classNameSlideContainer: 'block__slider__slides',
        classNamePrevCtrl: 'block__slider__prev',
        classNameNextCtrl: 'block__slider__next'
    };
    let sliderInfoTypes = document.querySelector('.block__info-types .block__slider');
    let sliderInfoAvtopark = document.querySelector('.block__info-avtopark .block__slider');
    let sliderOurProjects = document.querySelector('.block__photo-our-projects .block__slider');

    let dot_count         = sliderOurProjects.querySelectorAll('.block__one-slide').length;
    let dot_container     = sliderOurProjects.querySelector('.block__slider__dots');
    let dot_list_item     = document.createElement('li');

    function handleDotEvent(e) {
        if (e.type === 'before.lory.init') {
            for (var i = 0, len = dot_count; i < len; i++) {
                var clone = dot_list_item.cloneNode();
                dot_container.appendChild(clone);
            }
            dot_container.childNodes[0].classList.add('active');
        }
        if (e.type === 'after.lory.init') {
            for (var i = 0, len = dot_count; i < len; i++) {
                dot_container.childNodes[i].addEventListener('click', function(e) {
                    ourProjects.slideTo(Array.prototype.indexOf.call(dot_container.childNodes, e.target));
                });
            }
        }
        if (e.type === 'after.lory.slide') {
            for (var i = 0, len = dot_container.childNodes.length; i < len; i++) {
                dot_container.childNodes[i].classList.remove('active');
            }
            dot_container.childNodes[e.detail.currentSlide - 1].classList.add('active');
        }
        if (e.type === 'on.lory.resize') {
            for (var i = 0, len = dot_container.childNodes.length; i < len; i++) {
                dot_container.childNodes[i].classList.remove('active');
            }
            dot_container.childNodes[0].classList.add('active');
        }
    }

    sliderOurProjects.addEventListener('before.lory.init', handleDotEvent);
    sliderOurProjects.addEventListener('after.lory.init', handleDotEvent);
    sliderOurProjects.addEventListener('after.lory.slide', handleDotEvent);
    sliderOurProjects.addEventListener('on.lory.resize', handleDotEvent);


    lory(sliderInfoTypes, configSlider);
    lory(sliderInfoAvtopark, configSlider);
    let ourProjects = lory(sliderOurProjects, configSlider);

    // let font = new FontFaceObserver('Montserrat').load().then(function () {
    //     document.documentElement.className += " fonts-loaded";
    //     console.log('My Family has loaded');
    // });
    $(window).scroll(function(){
        var page = $(this).scrollTop();
        var content = $('main').offset().top - parseInt($('main').css("margin-top"));

        if( page > content )
        {
            if( $(window).width() < 992 )
            {
                $('#icon-burger--float').addClass('active');
            }
        }
        else
        {
            if( $(window).width() < 992 )
            {
                $('#icon-burger--float').removeClass('active');
            }
        }
    });

    $('input[name="city-from"],input[name="city-to"]').kladr({
        type: $.kladr.type.city
    });
    Inputmask({mask: "+7 (999) 999-9999", placeholder: "_", clearIncomplete: true  }).mask('input[name=phone]');

    var optionValidate =  {
        highlight: function(element, errorClass)
        {
            $(element).addClass('error-input');
            console.log('error');
        },
        unhighlight: function(element, errorClass, validClass)
        {
            $(element).removeClass('error-input');
        },
        errorElement: "span",
        submitHandler: function(form) {
            var dataForm = $(form).serialize();
            console.log('test');
            $.ajax({
                url: '/ajax/request.php',
                type: 'POST',
                data: dataForm + "&action=sendform",
                beforeSend: function()
                {
                    $(form).find('.preloader-bounce').addClass('active');
                }
            })
            .done(function(data) {
                    setTimeout(function () {
                        $('#thx-modal').trigger('click');
                        $(form).find('.preloader-bounce').removeClass('active');
                        $(form).trigger('reset');
                    }, 1500);
            })
            .fail(function() {
                console.log("error");
            })
            .always(function() {
                console.log("complete");
            });
            return false;
        },
        rules: {
            email: {
                required: false,
                email: true
            }
        },
        messages: {
            'city-from': "Необходимо заполнить пункт отправления",
            'city-to': "Необходимо заполнить пункт назначения",
            'phone': 'Необходимо ввести номер телефона',
            'email': 'Введен неверный email-адресс',
            'full-name': 'Необходимо ввести ФИО'
        }
    };

    $('form').each(function(){
        $(this).validate( optionValidate );
    });

    $('.order-call--button').fit_modal({
        fast_create : false,
        window_animation_type   : 'fade_in_top',
        frame_animation_speed   :  300,
        modal_title  :  'Заказать звонок',
        modal_content_block : '.content__order-call',
        active_custom_func : function () {
            if( $('#icon-burger').hasClass('active') ) {
                if( $('.modal__frame__burger').hasClass('open')) {
                    $('.modal__frame__burger').removeClass('open');
                    $('#icon-burger').removeClass('active');
                }
            }
        }
    });

    $('#burger-menu--close').on('click', function () {
        if( $('.modal__frame__burger').hasClass('open')) {
            $('.modal__frame__burger').removeClass('open');
            $('#icon-burger').removeClass('active');
        }
    });

    $('#icon-burger, #icon-burger--float').on('click', function () {
        if( !$('#icon-burger').hasClass('active')) {
            $('#icon-burger').addClass('active');
            $('.modal__frame__burger').addClass('open')
        }
    });
    $('[data-scroll-block]').on('click', function(){
        var scroll_el = $(this).data('scroll-block');
        if ($(scroll_el).length != 0) {
            if( $('.modal__frame__burger').hasClass('open')) {
                $('.modal__frame__burger').removeClass('open');
                $('#icon-burger').removeClass('active');
            }
            $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500);
        }
    });

    $('#thx-modal').fit_modal({
        fast_create : false,
        window_animation_type   : 'fade_in_top',
        frame_animation_speed   :  300,
        modal_title  :  'Спасибо за заказ!',
        modal_content_block : '.content__thx-page',
    });

});
